import useStyle from './style';
import { useContext, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../../utils/hooks/useAppDispatch';
import {
  useMediaQuery,
  Box,
  Skeleton,
  Typography,
  useTheme
} from '@mui/material';
import { ThemeContext } from '../../../../../utils/contexts/ThemeContext';
import Title from './components/Title';
import Logo from './components/Logo';
import Info from './components/Info';
import Action from './components/Action';
import { Sidebar } from '../../../pages/reviewer/components/Sidebar';
import ModalRoot from '../Root';
import analytics from '../../../../../utils/functions/analytics';
import { TranslateInterface } from '../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../utils/hooks/useLanguage';

type Props = {
  text?: string;
  summary?: string;
  open: boolean;
  toggle: () => void;
  type: string;
};

function ModalPremium({ open, toggle, type }: Props) {
  const classes = useStyle();

  const { translate }: TranslateInterface = useLanguage();

  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  return (
    <ModalRoot
      open={open}
      toggle={() => {
        toggle();
      }}
    >
      <Box
        className={classes.box}
        justifyContent="center"
        style={{
          // height: isMobile ? '600px' : '100%',
          maxHeight: isMobile ? '90vh' : '100%',
          width: isMobile ? '100%' : '444px',
          padding: isMobile ? '16px' : '24px',
          gap: '27px',
          touchAction: 'auto',
          textAlign: 'center'
        }}
      >
        <Logo />

        <Title>{translate('modal.premium.title')}</Title>

        <Info>
          {/*Você atingiu o limite de uso diário. Espere 24 horas para usar a ferramenta novamente ou cadastre-se para ter acesso ilimitado.*/}
          {translate('modal.premium.description')}
        </Info>

        <Action
          label={translate('modal.premium.action')}
          onClick={() => {
            analytics.logEvent(
              'tool-seo',
              `tool-${type}-premium-modal-sign-up`
            );

            window.open('https://clarice.ai/educacao', '_blank');
          }}
        />
      </Box>
    </ModalRoot>
  );
}

export default ModalPremium;
